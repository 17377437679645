.footer {
  background-color: var(--l-grey);
  color: #333;
  padding: 40px 0;

  border-radius: 8px;
  box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  flex: 1;
  margin-bottom: 20px;
  min-width: 200px;
}

.column h4 {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.column ul {
  list-style-type: none;
  padding: 0;
}

.column ul li {
  font-size: 15px;
  margin-bottom: 10px;
}

.column ul li a,
.main_site {
  font-size: 15px;
  color: #333;
  text-decoration: none;
}

.column ul li a:hover,
.main_site:hover {
  text-decoration: underline;
}

.copyright {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}

.dev {
  display: flex;
  gap: 4px;
  margin-top: 16px;
}
.dev,
.dev a {
  color: var(--gray-700);
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .column {
    width: 100%;
  }
}
