.wrapper {
  display: flex;
  align-items: center;
  gap: 20px 15px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.list {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  width: calc(100% - 32px);
  background: rgb(193, 230, 207);
  box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
  padding-top: 120px;
  transform: translateY(-100%);
  will-change: transform;

  @media screen and (768px <= width) {
    width: 290px;
    padding-top: 40px;
  }
}

.item {
  color: var(--dark);
  display: block;
  transform-origin: -20px 50%;
  font-weight: bold;
  font-size: 48px;
  padding: 10px;
  will-change: transform, opacity, filter;
}

.toggle {
  z-index: 3;
  outline: none;
  border: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  // top: 20px;
  right: 20px;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  // background: pink;
  padding: 10px;
  fill: var(--dark);
  stroke: var(--dark);
}

.user {
  display: flex;
  padding: 15px 8px 15px 7px;
  font-size: 16px;
  font-weight: 500;
}

.name {
  color: rgb(20, 67, 69);
}

.logout {
  padding: 14px 24px;
  font-weight: 600;
  font-size: 16px;
  color: #00f;
  background-color: var(--primary);
  cursor: pointer;
  border: 1px solid #00f;
  border-radius: 8px;
  transition: color 0.3s ease-in, background-color 0.25s ease-in;
}

.email {
  padding: 15px 7px 15px 8px;
  margin-right: 8px;
}

.logout:hover,
.logout:focus,
.logout:active {
  color: #8ccbf4;
  background-color: #00f;
}
