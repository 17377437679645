.container {
  position: relative;
}

.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  opacity: 1;
  width: 0px;
  height: 0px;
  transform: scale(0.001);
}

.page-exit-active {
  opacity: 0;
  width: 0px;
  height: 0px;
  transform: scale(0.001);
  transition: opacity 300ms, transform 30ms;
}

.node {
  &-enter {
    opacity: 0;
    color: red;

    &-active {
      opacity: 1;
      transition: opacity 2500ms;
    }
  }

  &-exit {
    opacity: 1;
    color: aqua;

    &-active {
      opacity: 0;
      transition: opacity 2500ms;
    }
  }
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 0;
}
.alert-exit-active {
  opacity: 0;
  // transform: scale(0.9);
  transition: opacity 30ms, transform 30ms;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
