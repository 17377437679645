@font-face {
  font-family: 'Pacifico';
  src: url('../../fonts/pacifico-latin-400.woff2') format('woff2'),
    url('../../fonts/pacifico-latin-400.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
.container {
  /* position: relative; */
  display: flex;
  justify-content: space-around;
  gap: 25px;
  flex-wrap: wrap;
}

.wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-inline: auto;
  /* border-bottom: 1px solid; */
}

.header {
  width: 100%;
  box-shadow: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
}

.navigation {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  width: 100%;
  background-color: var(--l-grey);
}

.list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  list-style: none;
  gap: 20px 40px;
  flex-direction: column;
  align-content: flex-start;

  @media screen and (768px <= width) {
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: row;
    width: 100%;
    list-style: none;
    gap: 20px 40px;
  }
}

.link {
  display: block;
  text-decoration: none;
  color: var(--gray-800);
  font-weight: 500;
}

.activeLink {
  display: block;
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  pointer-events: none;
}

.logo {
  /* font-family: 'Pacifico', sans-serif; */
  /* font-size: 28px; */
  border-bottom: none;
}

.brand-img {
  display: block;
}
