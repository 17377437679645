@import-normalize;

:root {
  --primary-color: rgb(49, 49, 49);
  --secondary-color: #ff4081;
  --error-color: rgb(192, 90, 90);

  --l-grey: #e6e7ee;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #a91e2c;
  --orange: #fd7e14;
  --yellow: #f0b400;
  --green: #18634b;
  --teal: #0056b3;
  --cyan: #17a2b8;
  --white: #ecf0f3;
  --gray: #93a5be;
  --gray-dark: #525480;
  --primary: #e6e7ee;
  --secondary: #2d4cc8;
  --success: #18634b;
  --info: #0056b3;
  --warning: #f0b400;
  --danger: #a91e2c;
  --light: #d1d9e6;
  --dark: #31344b;
  --default: #262833;
  --white: #ecf0f3;
  --gray-blue: #44476a;
  --neutral: #ecf0f3;
  --soft: #e6e7ee;
  --black: #1c1e31;
  --purple: #6f42c1;

  --gray-100: #f3f7fa;
  --gray-200: #fafbfe;
  --gray-300: #e6e7ee;
  --gray-400: #d1d9e6;
  --gray-500: #b1bcce;
  --gray-600: #93a5be;
  --gray-700: #66799e;
  --gray-800: #525480;

  --facebook: #3b5999;
  --dribbble: #ea4c89;
  --github: #222222;
  --behance: #0057ff;
  --twitter: #1da1f2;
  --slack: #3aaf85;

  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;

  --mobile: 375px;
  --mobile-max: 767.99px;
  --tablet: 768px;
  --tablet-max: 1439.99px;
  --desktop: 1440px;

  /* box-shadow */
  --shadow-box: 6px 6px 12px #b8b9be, -6px -6px 12px #fff;
  --shadow-inset-box: inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff;
  --shadow-inset-box-l: inset 2px 2px 5px #b8b9be, inset 3px 3px 7px #fff,
    inset -6px -6px 5px 0px #faf6f6;

  --border-radius: 0.3rem;

  ---gradient-qw: linear-gradient(
      180deg,
      #fffdf2 0,
      #fffdf2 3%,
      #fbf8f0 4%,
      #fbf8f0 5%,
      #fffdf2 6%,
      #fffdf2
    ),
    linear-gradient(
      90deg,
      #fffdf2 0,
      #fffdf2 2%,
      #fbf8f0 3%,
      #fbf8f0 4%,
      #fffdf2 5%,
      #fffdf2
    );
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
ol,
li {
  list-style: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--gray-blue);
  background-color: var(--primary);
}

a {
  text-decoration: none;
}

a {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

// SASS Variables
$mobile: var(--mobile);
$mobile-max: var(--mobile-max);
$tablet: var(--tablet);
$tablet-max: var(--tablet-max);
$desktop: var(--desktop);

// Mixins
@mixin mobile {
  @media (min-width: $mobile) {
    @content;
  }
}

@mixin mobile-max {
  @media (max-width: $mobile-max) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin tablet-max {
  @media (max-width: $tablet-max) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}
